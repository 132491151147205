<template>
    <section class="h-100">
        <div class="row mx-0 h-100">
            <div class="col-xl col-lg col-md col-sm col px-0 h-100 overflow-auto custom-scroll border-right">
                <div class="row mx-0 align-items-center px-2 border-bottom justify-content-end" style="height:56px;">
                    <div v-if="vista===1" class="btn-general f-14 px-3" @click="cerrarSoporte">
                        Finalizar reclamo
                    </div>
                    <div v-else class="d-middle f-14">
                        <p class="mr-3 text-general">
                            <b class="mr-1">Solicitud:</b>
                            15 Ago. 2020
                        </p>
                        <p class="text-general">
                            <b class="mr-1">Cierre:</b>
                            {{ detalle.fecha_ult_reclamo }}
                        </p>
                        <estrellas :row="detalle" class="mr-3 ml-2" />

                        <el-tooltip class="item" effect="light" :content="cliente.nombre || 'Sin Asignar'" placement="top">
                            <img
                            class="rounded-circle border bg-whitesmoke cr-pointer"
                            width="35" height="35"
                            :src="cliente.logo_mini"
                            />
                        </el-tooltip>
                    </div>
                </div>
                <div class="height-chat-padre" style="height:calc(100vh - 274px);">
                    <chat
                    :tipo="5"
                    :user="id_pedido"
                    :momento="2"
                    :finalizado="vista===2"
                    :fecha-finalizado="detalle.fecha_ult_reclamo"
                    @actualizar="actualizarPedidos"
                    @actualizar-lectura="actualizarLectura"
                    />
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md col-sm col px-0 h-100">
                <div class="d-flex f-14 mx-0 border-bottom" style="height:56px;">
                    <div
                    v-for="tab in tabs" :key="tab.id"
                    class="col px-0 h-100 d-middle-center text-center cr-pointer"
                    :class="activa==tab.components?'border-b-red text-general-red':''"
                    @click="activa=tab.components"
                    >
                        {{ tab.nombre }}
                    </div>
                </div>
                <div class="height-components overflow-auto custom-scroll" style="height:calc(100vh - 275px);">
                    <component :is="activa" :finalizado="activa=='ChatLeechero' || activa=='ChatCliente' ? true :false" />
                </div>
            </div>
        </div>
        <modalConfirmar ref="modalCerrarSoporte" titulo="Cerrar soporte" icon="checked" mensaje="¿Desea cerrar este caso?" @guardar="finalizarReclamo" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import ReclamosLeecheros from '~/services/reclamos/reclamosLeecheros'

export default {
    components: {
        // Detalle: () => import('./components/detalleLeechero'),
        Detalle: () => import('~/pages/pedidos/components/detalleAdminPedido.vue'),
        Productos: () => import('./components/productosLeechero'),
        ChatLeechero: () => import('./components/chatLeechero.vue'),
        ChatCliente: () => import('./components/chatCliente.vue'),
    },
    props:{

        vista: {
            type: Number,
            default: 1
        },
    },
    data(){
        return {
            activa: 'Detalle',
            tabs: [
                { id: 1, nombre: 'Detalle', components: 'Detalle'},
                { id: 2, nombre: 'Productos', components: 'Productos'},
                { id: 3, nombre: 'Chat Leechero', components: 'ChatLeechero'},
                { id: 4, nombre: 'Chat Cliente', components: 'ChatCliente'}
            ]
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            detalle: 'pedidos/pedidos_admin/pedido'
        }),
    },
    watch: {
        detalle(val){
            if(val){
                this.tabSegunModulo()
            }
        }
    },
    mounted(){
        this.tabSegunModulo()
        //console.log(this.tabs[2].nombre, 'Estilo');
        this.tabs[2].nombre = `Chat ${this.$config.vendedor}`
    },
    destroyed(){
        this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
        this.$store.commit('pedidos/pedidos_admin/set_productos', [])
    },
    methods: {
        cerrarSoporte(){
            this.$refs.modalCerrarSoporte.toggle()
        },
        tabSegunModulo(){
            if(this.detalle.id_pedido != null){// esta son 4 tabs
                this.tabs = [
                    { id: 1, nombre: 'Detalle', components: 'Detalle'},
                    { id: 2, nombre: 'Productos', components: 'Productos'},
                    { id: 3, nombre: `Chat ${this.$config.vendedor}`, components: 'ChatLeechero'},
                    { id: 4, nombre: `Chat ${this.$config.cliente }`, components: 'ChatCliente'}
                ]
            }else{
                this.tabs = [
                    { id: 1, nombre: 'Detalle', components: 'Detalle'},
                    { id: 2, nombre: 'Productos', components: 'Productos'},
                    { id: 3, nombre: 'Chat', components: 'ChatLeechero'},
                ]
            }

        },
        async finalizarReclamo(){
            try {

                const {data} = await ReclamosLeecheros.finalizarReclamo(this.detalle.id)
                this.notificacion('Mensaje', 'Reclamo finalizado', 'success')
                this.$emit('actualizar')
                this.$refs.modalCerrarSoporte.toggle()
                this.$store.commit('reclamos/reclamosLeecheros/set_id_pedido', 0)
                this.$store.commit("pedidos/pedidos_admin/set_id_pedido", null);

            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarPedidos(){
            try {
                console.log('actualizandooooooooooooooo');
                this.$emit('actualizar')
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarLectura(){
            console.log('-----  ----actualizarLecturaa-   -------', );
            await this.actualizarUltimaLectura()
        },
        async actualizarUltimaLectura(){
            try {
                const enviar ={
                    id_pedido: this.id_pedido
                }
                const {data} = await ReclamosLeecheros.actualizarUltimaLectura(enviar)

            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.height-chat-padre{
    height:calc(100vh - 217px) !important;
}
.height-components{
    height:calc(100vh - 217px) !important;
}

@media (max-width:1000px){
    .height-chat-padre{
        height:calc(100vh - 274px) !important;
    }
    .height-components{
        height:calc(100vh - 275px) !important;
    }
}
</style>
